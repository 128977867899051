import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Tab } from '@headlessui/react';
import { PlusIcon, ChevronDownIcon, ChevronUpIcon, FlagIcon, PencilIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import Cookies from 'js-cookie';
import imageCompression from 'browser-image-compression';
import ViewImage from './ViewImage';
import { savePhoto, getPhotos, clearPhotos } from '../utils/indexedDBUtil';
import LoadingScreen from './LoadingScreen';
import JSZip from 'jszip';
import EXIF from 'exif-js';
import { Accordion, ScrollArea, Stack, Text, Title } from '@mantine/core';

function ViewPhotos({ open, setOpen, project, refreshProject, setCachedPhotos }) {
    const [selectedSection, setSelectedSection] = useState(0);
    const [collapsedCollections, setCollapsedCollections] = useState({});
    const fileInputRef = useRef(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [showingImage, setShowingImage] = useState(false);
    const [imageToShow, setImageToShow] = useState(null);
    const [adminMode, setAdminMode] = useState(Cookies.get('uid') !== undefined);
    const [flaggedRows, setFlaggedRows] = useState({});
    const [loading, setLoading] = useState(false);
    const [savingComment, setSavingComment] = useState(null)
    const [savingNote, setSavingNote] = useState(null)
    const [openCrewNotes, setOpenCrewNotes] = useState([])
    const [openFeedbackRows, setOpenFeedbackRows] = useState([])
    const [checklistItems, setChecklistItems] = useState({});

    const toggleCollapse = (collectionId) => {
        setCollapsedCollections((prevState) => ({
            ...prevState,
            [collectionId]: !prevState[collectionId],
        }));
    };

    //console.log(project);

    const handlePhotoUpload = (rowId) => {
        setCurrentRowId(rowId);
        fileInputRef.current.click();
    };

    //collect all photos from a section
    const getAllSectionPhotos = (section) => {
        let allPhotos = [];
        section.rows.forEach(row => {
            allPhotos = allPhotos.concat(row.photos.map(photo => ({ ...photo, rowId: row.project_section_row_id })));
        });
        section.collections.forEach(collection => {
            collection.rows.forEach(row => {
                allPhotos = allPhotos.concat(row.photos.map(photo => ({ ...photo, rowId: row.project_section_row_id })));
            });
        });
        return allPhotos;
    };

    const incrementImage = (increment) => {
        setImageToShow(prevImage => {
            const newIndex = prevImage.index + increment;
            if (newIndex < 0) {
                return { ...prevImage, index: 0 };
            } else if (newIndex >= prevImage.sectionPhotos.length) {
                return { ...prevImage, index: prevImage.sectionPhotos.length - 1 };
            }
            const newPhoto = prevImage.sectionPhotos[newIndex];
            return {
                ...prevImage,
                index: newIndex,
                url: newPhoto.url,
                id: newPhoto.photo_id,
                rowId: newPhoto.rowId
            };
        });
    };

    // Update the click handler for individual photos
    const handlePhotoClick = (section, row, photoIndex) => {
        const sectionPhotos = getAllSectionPhotos(section);
        const overallIndex = sectionPhotos.findIndex(photo =>
            photo.photo_id === row.photos[photoIndex].photo_id
        );
        setImageToShow({
            url: row.photos[photoIndex].url,
            id: row.photos[photoIndex].photo_id,
            index: overallIndex,
            rowId: row.project_section_row_id,
            sectionPhotos: sectionPhotos
        });
        setShowingImage(true);
    };

    const [rowComments, setRowComments] = useState({});
    const [crewNotes, setCrewNotes] = useState({})


    useEffect(() => {
        if (project && project.sections) {
            const commentsMap = {};
            const crewNotesMap = {}
            project.sections.forEach(section => {
                section.rows.forEach(row => {
                    commentsMap[row.project_section_row_id] = row.comments || '';
                    crewNotesMap[row.project_section_row_id] = row.crew_notes || '';
                });
                section.collections.forEach(collection => {
                    collection.rows.forEach(row => {
                        commentsMap[row.project_section_row_id] = row.comments || '';
                        crewNotesMap[row.project_section_row_id] = row.crew_notes || '';
                    })
                });
            });
            setRowComments(commentsMap);
            setCrewNotes(crewNotesMap)
        }
    }, [project]);

    useEffect(() => {
        if (project && project.sections) {
            const newChecklistItems = {};
            project.sections.forEach(section => {
                section.rows.forEach(row => {
                    if (row.checklistItems) {
                        newChecklistItems[row.project_section_row_id] = row.checklistItems.map(item => ({ ...item, projectSectionRowId: row.project_section_row_id }));
                    }
                });
                section.collections.forEach(collection => {
                    collection.rows.forEach(row => {
                        if (row.checklistItems) {
                            newChecklistItems[row.project_section_row_id] = row.checklistItems.map(item => ({ ...item, projectSectionRowId: row.project_section_row_id }));
                        }
                    });
                });
            });
            setChecklistItems(newChecklistItems);
        }
    }, [project]);


    const updateRowComment = (project_section_row_id, newComment) => {
        setRowComments(prevComments => ({
            ...prevComments,
            [project_section_row_id]: newComment
        }));
    };

    const updateRowNote = (project_section_row_id, newNote) => {
        setCrewNotes(prevNotes => ({
            ...prevNotes,
            [project_section_row_id]: newNote
        }));
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const photosToAdd = [];

        const compressAndReadFile = async (file) => {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };

            const getExifData = (file) => {
                return new Promise((resolve) => {
                    EXIF.getData(file, function () {
                        // Try multiple EXIF tags that might contain the date
                        const dateTimeOriginal = EXIF.getTag(this, "DateTimeOriginal");
                        const dateTime = EXIF.getTag(this, "DateTime");
                        const createDate = EXIF.getTag(this, "CreateDate");
                        
                        // Log all available EXIF data for debugging
                        console.log('EXIF Data:', {
                            dateTimeOriginal,
                            dateTime,
                            createDate,
                            allTags: EXIF.getAllTags(this)
                        });

                        // Try each date field in order of preference
                        const dateString = dateTimeOriginal || dateTime || createDate;
                        
                        if (dateString) {
                            // Handle different date formats
                            const date = new Date(dateString.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3'));
                            resolve(date);
                        } else {
                            // If no EXIF date found, use file's last modified date
                            resolve(new Date(file.lastModified));
                        }
                    });
                });
            };

            try {
                const compressedFile = await imageCompression(file, options);
                const originalDate = await getExifData(file); // Get original date from uncompressed file

                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (upload) => {
                        console.log('originalDate:', originalDate);
                        const photoData = {
                            projectId: project.project_id,
                            projectSectionRowId: currentRowId,
                            imageBlob: upload.target.result,
                            takenAt: originalDate ? originalDate.toISOString() : null // Include the original date if available
                        };
                        resolve(photoData);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(compressedFile);
                });
            } catch (error) {
                console.error("Error compressing file:", error);
                throw error;
            }
        };

        try {
            setLoading(true);
            const compressedPhotos = await Promise.all(files.map(compressAndReadFile));
            photosToAdd.push(...compressedPhotos);

            if (navigator.onLine) {
                await uploadPhotos(photosToAdd);
            } else {
                photosToAdd.forEach(photo => savePhoto(photo));
                setLoading(false);
                alert('No internet connection. Photos have been saved locally, please press the orange re-upload button from the day of view when reconnected to internet.');
                setCachedPhotos(true);
            }
        } catch (error) {
            console.error('Error handling files:', error);
            setLoading(false);
        }
    };

    const uploadPhotos = async (photosToAdd) => {
        const chunkSize = 5; // Number of photos to upload in parallel
        for (let i = 0; i < photosToAdd.length; i += chunkSize) {
            const chunk = photosToAdd.slice(i, i + chunkSize);
            await Promise.all(chunk.map(async (photo) => {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, {
                        projectId: project.project_id,
                        photosToAdd: [photo]
                    });
                    if (response.status !== 200) {
                        throw new Error('Upload failed');
                    }
                } catch (error) {
                    console.error('Error uploading photo:', error);
                    await savePhoto(photo); // Save failed upload to IndexedDB
                    setCachedPhotos(true);
                }
            }));
        }
        refreshProject().then(() => setLoading(false));
    };

    const addComment = async (projectSectionRowId, comment) => {
        try {
            setLoading(true);
            setSavingComment(projectSectionRowId)
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, commentsToAdd: [{ projectSectionRowId: projectSectionRowId, comment }] });
            if (response.status === 200) {
                refreshProject().then(() => {
                    setLoading(false);
                    setSavingComment(null)
                });
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const addCrewNotes = async (projectSectionRowId, notes) => {
        try {
            setLoading(true);
            setSavingNote(projectSectionRowId)
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, notesToAdd: [{ projectSectionRowId: projectSectionRowId, notes }] });
            if (response.status === 200) {
                refreshProject().then(() => {
                    setLoading(false);
                    setSavingNote(null)
                });
            }
        } catch (error) {
            console.error('Error adding note:', error);
        }
    };


    const handleFlagClick = async (rowId, isRed) => {
        let currentFlag = flaggedRows[rowId];
        let newFlag;

        if (currentFlag === isRed) {
            newFlag = null; // Reset flag if the same flag is clicked again
        } else {
            newFlag = isRed; // Toggle to the new flag state
        }

        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, flagsToAdd: [{ projectSectionRowId: rowId, flag: newFlag }] });
            if (response.status === 200) {
                setFlaggedRows((prevState) => ({
                    ...prevState,
                    [rowId]: newFlag
                }));
                refreshProject().then(() => setLoading(false));
            }
        } catch (error) {
            console.error('Error setting flag:', error);
        }
    };

    const countRequiredPhotos = () => {
        let count = 0;
        project.sections.forEach(section => {
            section.rows.forEach(row => {
                if (row.required && (!row.photos || row.photos.length === 0)) count++;
            });
            section.collections.forEach(collection => {
                collection.rows.forEach(row => {
                    if (row.required && (!row.photos || row.photos.length === 0)) count++;
                });
            });
        });
        return count;
    };

    const deleteImage = async () => {
        try {
            const photosToRemove = [imageToShow.id];
            setLoading(true);
            setShowingImage(false);
            setImageToShow(null);
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, photosToRemove });
            if (response.status === 200) {
                refreshProject().then(() => setLoading(false));
            }

        } catch (error) {
            console.error('Error deleting image:', error);

        }
    }

    const handleCheckboxChange = async (rowId, itemId) => {
        setChecklistItems(prevItems => {
            const updatedItems = prevItems[rowId].map(item =>
                item.id === itemId ? { ...item, checked: !item.checked, projectSectionRowId: rowId } : item
            );

            const newItems = {
                ...prevItems,
                [rowId]: updatedItems
            };

            // Make the API call here, after the state has been updated
            updateChecklistItemsOnServer(rowId, updatedItems);

            return newItems;
        });
    };

    const updateChecklistItemsOnServer = async (rowId, updatedItems) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, {
                projectId: project.project_id,
                checklistItemsToUpdate: [{ projectSectionRowId: rowId, checklistItemsToUpdate: updatedItems }]
            });

            if (response.status !== 200) {
                console.error('Error updating checklist items on server');
            }
        } catch (error) {
            console.error('Error updating checklist items:', error);
        }
    };

    const handleBulkDownload = async (photos, rowName) => {
        if (!photos || photos.length === 0) return;

        console.log('Photos:', photos);

        const zip = new JSZip();
        const folder = zip.folder(rowName || "photos"); // Use rowName or default to "photos"

        const fetchPhoto = (photo) => {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', photo.url, true);
                xhr.responseType = 'blob';

                // Add necessary headers
                xhr.setRequestHeader('Accept', 'image/jpeg, image/png, image/jpg');

                xhr.onload = function () {
                    if (this.status === 200) {
                        resolve(this.response);
                    } else {
                        reject(new Error(`Failed to fetch ${photo.url}`));
                    }
                };

                xhr.onerror = function () {
                    reject(new Error(`Network error while fetching ${photo.url}`));
                };

                xhr.send();
            });
        };

        try {
            const photoPromises = photos.map(async (photo, index) => {
                try {
                    const blob = await fetchPhoto(photo);
                    folder.file(`photo_${index + 1}.jpg`, blob);
                    console.log(`Successfully added photo ${index + 1}`);
                } catch (error) {
                    console.error(`Error fetching photo ${index + 1}:`, error);
                }
            });

            await Promise.all(photoPromises);

            // Generate the zip file and trigger download
            const content = await zip.generateAsync({ type: "blob" });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `${rowName || "photos"}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error in bulk download:', error);
        }
    };

    //console.log(project);

    return (
        <>
            <ViewImage
                imageToShow={imageToShow}
                open={showingImage}
                setOpen={setShowingImage}
                handleDelete={deleteImage}
                incrementImage={incrementImage}
                project={project}
            />
            <LoadingScreen open={loading} setOpen={setLoading} />

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={showingImage ? () => { } : () => setOpen(false)}>
                    <div className="flex min-h-screen items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-5xl transform overflow-hidden rounded-lg bg-white p-2 text-left align-middle shadow-xl transition-all">
                                <div className="px-4 py-3 sm:px-6 text-center border-b border-gray-200">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Document install progress for job close out with photos
                                    </h3>
                                </div>
                                <Tab.Group selectedIndex={selectedSection} onChange={setSelectedSection}>
                                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                                        {project.sections.map((section) => (
                                            <Tab
                                                key={section.section_id}
                                                className={({ selected }) =>
                                                    `w-full py-2.5 text-sm leading-5 font-medium rounded-lg focus:outline-none ${selected ? 'bg-gray-500 text-white shadow' : 'bg-white text-gray-700 hover:bg-gray-200'}`
                                                }
                                            >
                                                {section.name}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                    <div className="border-t border-gray-200 my-4"></div>
                                    <Tab.Panels className="">
                                        {project.sections.map((section) => (
                                            <Tab.Panel
                                                key={section.section_id}
                                                className="p-3 bg-white rounded-xl focus:outline-none ring-white ring-opacity-60"
                                            >
                                                {section.rows.length > 0 && (
                                                    <div>
                                                        {section.rows
                                                            .sort((a, b) => a.row_id - b.row_id)
                                                            .map((row) => (
                                                                <div
                                                                    key={row.row_id}
                                                                    className={`border-b py-4 mb-4 relative items-center ${row.flagged_bad === true ? 'bg-red-200' : row.flagged_bad === false ? 'bg-green-200' : ''}`}>
                                                                    <div className="flex flex-col flex-grow mr-4">
                                                                        <div className="flex items-center justify-between">                                                                           
                                                                            <div className="flex flex-row">
                                                                                <div className="font-bold mr-2">{row.name}{!row.required && <span className="ml-2 text-xs">(Optional)</span>}</div>
                                                                                {adminMode && (
                                                                                    <div className="flex space-x-1">
                                                                                        <FlagIcon
                                                                                            className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] === false ? 'text-green-600' : 'text-green-200'}`}
                                                                                            onClick={() => handleFlagClick(row.project_section_row_id, false)}
                                                                                        />
                                                                                        <FlagIcon
                                                                                            className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] ? 'text-red-600' : 'text-red-200'}`}
                                                                                            onClick={() => handleFlagClick(row.project_section_row_id, true)}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>                                                                      
                                                                            <button
                                                                                className="flex-none text-center justify-end items-center w-9 h-9 rounded bg-gray-200 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                                onClick={() => handlePhotoUpload(row.project_section_row_id)}
                                                                            >
                                                                                <PlusIcon className="" />
                                                                            </button>

                                                                        </div>
                                                                        <div className="text-sm text-gray-500">{row.description}</div>
                                                                        {row.checklistItems && checklistItems[row.project_section_row_id] && checklistItems[row.project_section_row_id].length > 0 && (
                                                                            <div className="mt-2">
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {checklistItems[row.project_section_row_id].map((item) => (
                                                                                        <div
                                                                                            key={item.id}
                                                                                            className={`flex items-center p-2 rounded ${item.checked ? 'bg-green-200' : 'bg-red-200'}`}
                                                                                        >
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={item.checked}
                                                                                                onChange={() => handleCheckboxChange(row.project_section_row_id, item.id)}
                                                                                                className="mr-2 checklist-checkbox h-5 w-5 text-green-600 rounded-full transition duration-150 ease-in-out"
                                                                                            />
                                                                                            <span className="text-sm">{item.name}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="mt-2">
                                                                            <div className="hidden sm:grid sm:grid-cols-3 md:grid-cols-4 gap-4">
                                                                                {row.photos
                                                                                    .sort((a, b) => {
                                                                                        if (!a.taken_at && !b.taken_at) {
                                                                                            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                                                                                        }
                                                                                        if (!a.taken_at) return 1;
                                                                                        if (!b.taken_at) return -1;
                                                                                        return new Date(a.taken_at).getTime() - new Date(b.taken_at).getTime();
                                                                                    })
                                                                                    .map((image, index) => (
                                                                                        <div
                                                                                            key={image.photo_id}
                                                                                            className="flex-none w-40 max-h-fit relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200"
                                                                                            onClick={() => handlePhotoClick(section, row, index)}
                                                                                        >
                                                                                            <div className="aspect-w-1 aspect-h-1">
                                                                                                <img
                                                                                                    src={image.url}
                                                                                                    alt={`${image.photo_id}`}
                                                                                                    className="object-cover w-full h-full"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="p-2 text-xs text-gray-500">
                                                                                                <div>Photo {index + 1}</div>
                                                                                                <div>{image.taken_at ?
                                                                                                    new Date(image.taken_at).toLocaleDateString('en-US', {
                                                                                                        year: 'numeric',
                                                                                                        month: 'short',
                                                                                                        day: '2-digit',
                                                                                                        hour: '2-digit',
                                                                                                        minute: '2-digit'
                                                                                                    }) :
                                                                                                    'No date available'}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>

                                                                            {/* Horizontal scroll for small screens */}
                                                                            <div className="block sm:hidden">
                                                                                <ScrollArea>
                                                                                    <div className="flex gap-3 min-w-0 py-8">
                                                                                        {row.photos
                                                                                            .sort((a, b) => {
                                                                                                if (!a.taken_at && !b.taken_at) {
                                                                                                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                                                                                                }
                                                                                                if (!a.taken_at) return 1;
                                                                                                if (!b.taken_at) return -1;
                                                                                                return new Date(a.taken_at).getTime() - new Date(b.taken_at).getTime();
                                                                                            })
                                                                                            .map((image, index) => (
                                                                                                <div
                                                                                                    key={image.photo_id}
                                                                                                    className="flex-none w-32 relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200"
                                                                                                    onClick={(e) => {
                                                                                                        handlePhotoClick(section, row, index);
                                                                                                        e.stopPropagation();
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="aspect-w-1 aspect-h-1">
                                                                                                        <img
                                                                                                            src={image.url}
                                                                                                            alt={`${image.photo_id}`}
                                                                                                            className="object-cover w-full h-full"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="p-2 text-xs text-gray-500">
                                                                                                        <div>Photo {index + 1}</div>
                                                                                                        <div>{image.taken_at ?
                                                                                                            new Date(image.taken_at).toLocaleDateString('en-US', {
                                                                                                                month: 'short',
                                                                                                                day: '2-digit',
                                                                                                                hour: '2-digit',
                                                                                                                minute: '2-digit'
                                                                                                            }) :
                                                                                                            'No date'
                                                                                                        }</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                </ScrollArea>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            openCrewNotes.includes(row.project_section_row_id) ? (
                                                                                <div className="mt-2">
                                                                                    <>
                                                                                        <textarea
                                                                                            placeholder="Add notes (optional)"
                                                                                            className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                            value={crewNotes[row.project_section_row_id] || ''}
                                                                                            onChange={(e) => updateRowNote(row.project_section_row_id, e.target.value)}
                                                                                        />
                                                                                        <button
                                                                                            className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                            onClick={() => setOpenCrewNotes(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                        >
                                                                                            Close
                                                                                        </button>
                                                                                        <button
                                                                                            className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                            onClick={() => addCrewNotes(row.project_section_row_id, crewNotes[row.project_section_row_id])}
                                                                                        >
                                                                                            {savingNote === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                        </button>
                                                                                    </>
                                                                                </div>
                                                                            )
                                                                                :
                                                                                row.crew_notes ?
                                                                                    (
                                                                                        (
                                                                                            <div className='mt-2 flex items-center'>
                                                                                                {row.photos.length > 0 && adminMode && (
                                                                                                    <button
                                                                                                        className="mr-2 px-3 py-1 bg-green-600 text-white rounded-md shadow hover:bg-green-700 transition-colors duration-200"
                                                                                                        onClick={() => handleBulkDownload(row.photos, row.name)}
                                                                                                    >
                                                                                                        Download Photos ({row.photos.length})
                                                                                                    </button>
                                                                                                )}

                                                                                                <p className='italic text-sm'>Notes: {row.crew_notes ? row.crew_notes : ''}</p>
                                                                                                <PencilIcon
                                                                                                    className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                    onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}

                                                                                                />
                                                                                            </div>

                                                                                        )
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <div className="mt-2 flex gap-2">
                                                                                            <button
                                                                                                className="px-3 py-1 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}
                                                                                            >
                                                                                                Add Notes
                                                                                            </button>
                                                                                            {row.photos.length > 0 && adminMode && (
                                                                                                <button
                                                                                                    className="px-3 py-1 bg-green-600 text-white rounded-md shadow hover:bg-green-700 transition-colors duration-200"
                                                                                                    onClick={() => handleBulkDownload(row.photos, row.name)}
                                                                                                >
                                                                                                    Download Photos ({row.photos.length})
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                        }
                                                                        {row.flagged_bad && (
                                                                            <div className="mt-2">
                                                                                {(adminMode && openFeedbackRows.includes(row.project_section_row_id)) ? (
                                                                                    <>
                                                                                        <textarea
                                                                                            placeholder="Enter reason for flagging"
                                                                                            className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                            value={rowComments[row.project_section_row_id] || ''}
                                                                                            onChange={(e) => updateRowComment(row.project_section_row_id, e.target.value)}
                                                                                        />
                                                                                        <button
                                                                                            className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                            onClick={() => setOpenFeedbackRows(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                        >
                                                                                            Close
                                                                                        </button>
                                                                                        <button
                                                                                            className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                            onClick={() => addComment(row.project_section_row_id, rowComments[row.project_section_row_id])}
                                                                                        >
                                                                                            {savingComment === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                        </button>
                                                                                    </>
                                                                                ) : (

                                                                                    <div className='mt-2 flex items-center'>
                                                                                        <p className='italic'>Feedback: {row.comments ? row.comments : ''}</p>
                                                                                        <PencilIcon
                                                                                            className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                            onClick={() => setOpenFeedbackRows([...openFeedbackRows, row.project_section_row_id])}

                                                                                        />
                                                                                    </div>

                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                                {section.collections.length > 0 && (
                                                    <Accordion multiple defaultValue={section.collections.map(collection => collection.name)} variant="separated" radius="lg">
                                                        {section.collections
                                                            .sort((a, b) => a.collection_id - b.collection_id)
                                                            .map((collection) => (
                                                                <Accordion.Item
                                                                    key={collection.collection_id}
                                                                    value={collection.name}


                                                                >
                                                                    <Accordion.Control>
                                                                        <Stack gap="xs">
                                                                            <Title order={3}>{collection.name}</Title>
                                                                            <Text>{collection.description}</Text>
                                                                        </Stack>
                                                                    </Accordion.Control>


                                                                    <Accordion.Panel>
                                                                        <div className="ml-4 mt-2">
                                                                            {collection.rows
                                                                                .sort((a, b) => a.row_id - b.row_id)
                                                                                .map((row) => (
                                                                                    <div
                                                                                        key={row.row_id}
                                                                                        className={`border-b py-2 relative items-center ${row.flagged_bad === true ? 'bg-red-200' : row.flagged_bad === false ? 'bg-green-200' : ''}`}>
                                                                                        <div className="flex flex-col flex-grow mr-4">
                                                                                            <div className="flex items-center justify-between">
                                                                                                <div className="flex flex-row">
                                                                                                    <div className="font-bold mr-2">{row.name}{!row.required && <span className="ml-2 text-xs">(Optional)</span>}</div>
                                                                                                    {adminMode && (
                                                                                                        <div className="flex space-x-1">
                                                                                                            <FlagIcon
                                                                                                                className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] === false ? 'text-green-600' : 'text-green-200'}`}
                                                                                                                onClick={() => handleFlagClick(row.project_section_row_id, false)}
                                                                                                            />
                                                                                                            <FlagIcon
                                                                                                                className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] ? 'text-red-600' : 'text-red-200'}`}
                                                                                                                onClick={() => handleFlagClick(row.project_section_row_id, true)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>                                                                                              
                                                                                                <button
                                                                                                    className="flex-none text-center justify-end items-center w-9 h-9 rounded bg-gray-200 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                                                    onClick={() => handlePhotoUpload(row.project_section_row_id)}
                                                                                                >
                                                                                                    <PlusIcon className="" />
                                                                                                </button>

                                                                                            </div>
                                                                                            <div className="text-sm text-gray-500">{row.description}</div>
                                                                                            {row.checklistItems && checklistItems[row.project_section_row_id] && checklistItems[row.project_section_row_id].length > 0 && (
                                                                                                <div className="mt-2">
                                                                                                    <div className="flex flex-wrap gap-2">
                                                                                                        {checklistItems[row.project_section_row_id].map((item) => (
                                                                                                            <div
                                                                                                                key={item.id}
                                                                                                                className={`flex items-center p-2 rounded ${item.checked ? 'bg-green-200' : 'bg-red-200'}`}
                                                                                                            >
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    checked={item.checked}
                                                                                                                    onChange={() => handleCheckboxChange(row.project_section_row_id, item.id)}
                                                                                                                    className="mr-2 checklist-checkbox h-5 w-5 text-green-600 rounded-full transition duration-150 ease-in-out"
                                                                                                                />
                                                                                                                <span className="text-sm">{item.name}</span>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="mt-2">
                                                                                                <div className="hidden sm:grid sm:grid-cols-3 md:grid-cols-4 gap-4">
                                                                                                    {row.photos
                                                                                                        .sort((a, b) => {
                                                                                                            if (!a.taken_at && !b.taken_at) {
                                                                                                                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                                                                                                            }
                                                                                                            if (!a.taken_at) return 1;
                                                                                                            if (!b.taken_at) return -1;
                                                                                                            return new Date(a.taken_at).getTime() - new Date(b.taken_at).getTime();
                                                                                                        })
                                                                                                        .map((image, index) => (
                                                                                                            <div
                                                                                                                key={image.photo_id}
                                                                                                                className="flex-none w-40 max-h-fit relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200"
                                                                                                                onClick={() => handlePhotoClick(section, row, index)}
                                                                                                            >
                                                                                                                <div className="aspect-w-1 aspect-h-1">
                                                                                                                    <img
                                                                                                                        src={image.url}
                                                                                                                        alt={`${image.photo_id}`}
                                                                                                                        className="object-cover w-full h-full"
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="p-2 text-xs text-gray-500">
                                                                                                                    <div>Photo {index + 1}</div>
                                                                                                                    <div>{image.taken_at ?
                                                                                                                        new Date(image.taken_at).toLocaleDateString('en-US', {
                                                                                                                            year: 'numeric',
                                                                                                                            month: 'short',
                                                                                                                            day: '2-digit',
                                                                                                                            hour: '2-digit',
                                                                                                                            minute: '2-digit'
                                                                                                                        }) :
                                                                                                                        'No date available'}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                </div>

                                                                                                {/* Horizontal scroll for small screens */}
                                                                                                <div className="block sm:hidden">
                                                                                                    <ScrollArea>
                                                                                                        <div className="flex gap-3 min-w-0 py-8">
                                                                                                            {row.photos
                                                                                                                .sort((a, b) => {
                                                                                                                    if (!a.taken_at && !b.taken_at) {
                                                                                                                        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                                                                                                                    }
                                                                                                                    if (!a.taken_at) return 1;
                                                                                                                    if (!b.taken_at) return -1;
                                                                                                                    return new Date(a.taken_at).getTime() - new Date(b.taken_at).getTime();
                                                                                                                })
                                                                                                                .map((image, index) => (
                                                                                                                    <div
                                                                                                                        key={image.photo_id}
                                                                                                                        className="flex-none w-32 relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200"
                                                                                                                        onClick={(e) => {
                                                                                                                            handlePhotoClick(section, row, index);
                                                                                                                            e.stopPropagation();
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="aspect-w-1 aspect-h-1">
                                                                                                                            <img
                                                                                                                                src={image.url}
                                                                                                                                alt={`${image.photo_id}`}
                                                                                                                                className="object-cover w-full h-full"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div className="p-2 text-xs text-gray-500">
                                                                                                                            <div>Photo {index + 1}</div>
                                                                                                                            <div>{image.taken_at ?
                                                                                                                                new Date(image.taken_at).toLocaleDateString('en-US', {
                                                                                                                                    month: 'short',
                                                                                                                                    day: '2-digit',
                                                                                                                                    hour: '2-digit',
                                                                                                                                    minute: '2-digit'
                                                                                                                                }) :
                                                                                                                                'No date'
                                                                                                                            }</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                        </div>
                                                                                                    </ScrollArea>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                openCrewNotes.includes(row.project_section_row_id) ? (
                                                                                                    <div className="mt-2">
                                                                                                        <>
                                                                                                            <textarea
                                                                                                                placeholder="Add notes (optional)"
                                                                                                                className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                                                value={crewNotes[row.project_section_row_id] || ''}
                                                                                                                onChange={(e) => updateRowNote(row.project_section_row_id, e.target.value)}
                                                                                                            />
                                                                                                            <button
                                                                                                                className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                                                onClick={() => setOpenCrewNotes(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                                            >
                                                                                                                Close
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                                onClick={() => addCrewNotes(row.project_section_row_id, crewNotes[row.project_section_row_id])}
                                                                                                            >
                                                                                                                {savingNote === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    </div>
                                                                                                )
                                                                                                    :
                                                                                                    row.crew_notes ?
                                                                                                        (
                                                                                                            (
                                                                                                                <div className='mt-2 flex items-center'>
                                                                                                                    {row.photos.length > 0 && adminMode && (
                                                                                                                        <button
                                                                                                                            className="mr-2 px-3 py-1 bg-green-600 text-white rounded-md shadow hover:bg-green-700 transition-colors duration-200"
                                                                                                                            onClick={() => handleBulkDownload(row.photos, row.name)}
                                                                                                                        >
                                                                                                                            Download Photos ({row.photos.length})
                                                                                                                        </button>
                                                                                                                    )}

                                                                                                                    <p className='italic text-sm'>Notes: {row.crew_notes ? row.crew_notes : ''}</p>
                                                                                                                    <PencilIcon
                                                                                                                        className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                                        onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}

                                                                                                                    />
                                                                                                                </div>

                                                                                                            )
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <div className="mt-2 flex gap-2">
                                                                                                                <button
                                                                                                                    className="px-3 py-1 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                                    onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}
                                                                                                                >
                                                                                                                    Add Notes
                                                                                                                </button>
                                                                                                                {row.photos.length > 0 && adminMode && (
                                                                                                                    <button
                                                                                                                        className="px-3 py-1 bg-green-600 text-white rounded-md shadow hover:bg-green-700 transition-colors duration-200"
                                                                                                                        onClick={() => handleBulkDownload(row.photos, row.name)}
                                                                                                                    >
                                                                                                                        Download Photos ({row.photos.length})
                                                                                                                    </button>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )
                                                                                            }
                                                                                            {row.flagged_bad && (
                                                                                                <div className="mt-2">
                                                                                                    {(adminMode && openFeedbackRows.includes(row.project_section_row_id)) ? (
                                                                                                        <>
                                                                                                            <textarea
                                                                                                                placeholder="Enter reason for flagging"
                                                                                                                className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                                                value={rowComments[row.project_section_row_id] || ''}
                                                                                                                onChange={(e) => updateRowComment(row.project_section_row_id, e.target.value)}
                                                                                                            />
                                                                                                            <button
                                                                                                                className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                                                onClick={() => setOpenFeedbackRows(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                                            >
                                                                                                                Close
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                                onClick={() => addComment(row.project_section_row_id, rowComments[row.project_section_row_id])}
                                                                                                            >
                                                                                                                {savingComment === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    ) : (

                                                                                                        <div className='mt-2 flex items-center'>
                                                                                                            <p className='italic'>Feedback: {row.comments ? row.comments : ''}</p>
                                                                                                            <PencilIcon
                                                                                                                className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                                onClick={() => setOpenFeedbackRows([...openFeedbackRows, row.project_section_row_id])}

                                                                                                            />
                                                                                                        </div>

                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>

                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    </Accordion.Panel>
                                                                </Accordion.Item>
                                                            ))}
                                                    </Accordion>
                                                )}
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </Tab.Group>
                                <div className="mt-4 flex flex-col items-center w-full">
                                    <button
                                        type="button"
                                        className="w-full mx-4 inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        onClick={() => setOpen(false)}
                                    >
                                        {loading ?
                                            (<p className='text-gray-700'>Loading...</p>)
                                            : (
                                                'Close'
                                            )}
                                    </button>
                                    <div className="text-center mt-2 text-blue-500">
                                        {countRequiredPhotos()} required photos remaining
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" onChange={handleFileChange} multiple />
        </>
    );
}

export default ViewPhotos;
