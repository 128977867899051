import { Dialog } from '@headlessui/react'
import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

export default function LoadingScreen({ open, setOpen }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const intervals = [1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400]; // Increasing delays
        let currentProgress = 0;

        const timer = setInterval(() => {
            if (currentProgress < intervals.length) {
                setProgress(prev => {
                    const newProgress = prev + 5;
                    return newProgress > 95 ? 95 : newProgress;
                });
                currentProgress++;
            } else {
                clearInterval(timer);
            }
        }, intervals[currentProgress]);

        return () => clearInterval(timer);
    }, []);


    return (
        <Dialog open={open} onClose={() => (null)} className="relative z-50">
            <Dialog.Backdrop
                className="fixed z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <ClockIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Upload in progress
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Sit tight while we upload your images.                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}
