import React, { useEffect, useState } from 'react';
import { Modal, Image, Group, Button, Text, Stack, Container } from '@mantine/core';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

function ViewImage({ imageToShow, open, setOpen, handleDelete, incrementImage, project }) {
    const [isZoomed, setIsZoomed] = useState(false);
    const [transformOrigin, setTransformOrigin] = useState('center center');

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                incrementImage(1);
            } else if (event.key === 'ArrowLeft') {
                incrementImage(-1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [incrementImage]);
    
    if (!imageToShow) return null;

    const currentRow = project.sections
        .flatMap(section => [...section.rows, ...section.collections.flatMap(c => c.rows)])
        .find(row => row.project_section_row_id === imageToShow.rowId);

    const handleClose = (event) => {
       // event.stopPropagation();
        setOpen(false);
    };

    const handleDownload = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = imageToShow.url;
        link.download = 'downloadedImage';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const enhancedHandleDelete = (event) => {
        event.preventDefault();
        handleDelete();
    };

    const handleImageClick = (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left; // x position within the element
        const y = event.clientY - rect.top;  // y position within the element

        // Calculate the transform origin as a percentage
        const xPercent = (x / rect.width) * 100;
        const yPercent = (y / rect.height) * 100;

        setTransformOrigin(`${xPercent}% ${yPercent}%`);
        setIsZoomed(!isZoomed);
    };

    return (
        <Modal
            opened={open}
            onClose={handleClose}
            size="xl"
            radius="lg"
            centered
            withCloseButton={false}
            closeOnClickOutside={true}
            closeOnEscape={true}
            padding="md"
        >
            <div className="relative">
                <div className="absolute top-0 right-0 pt-4 pr-4 z-50">
                    <Button
                        variant="white"
                        color="red"
                        radius="xl"
                        size="xs"
                        className="absolute top-2 right-2 z-50"
                        onClick={handleClose}
                    >
                        <XMarkIcon className="h-4 w-4" />
                    </Button>
                </div>

                <Stack align="center" spacing="md">
                    <div className="relative">
                        <Container fluid h={isZoomed ? '100%' : 'auto'} className="overflow-hidden">
                        <Image
                            src={imageToShow?.url}
                            alt="Full size"
                            radius="md"
                            fit="fill"
                            withPlaceholder
                            placeholder={<div className="w-full h-full bg-gray-100 animate-pulse" />}
                            onClick={handleImageClick}
                            style={{
                                cursor: isZoomed ? 'zoom-out' : 'zoom-in',
                                transform: isZoomed ? 'scale(3)' : 'scale(1)',
                                transformOrigin: transformOrigin,
                                transition: 'transform 0.3s ease'
                            }}
                        />
                        </Container>
                        <Group position="center" spacing="md" className="absolute bottom-2 left-1/2 transform -translate-x-1/2">
                            <Button
                                variant="filled"
                                color="gray"
                                radius="xl"
                                disabled={imageToShow?.index === 0}
                                onClick={() => {
                                    incrementImage(-1);
                                    setIsZoomed(false);
                                }}
                            >
                                <ChevronLeftIcon className="h-6 w-6" />
                            </Button>
                            <Button
                                variant="filled"
                                color="gray"
                                radius="xl"
                                disabled={imageToShow?.index === imageToShow?.row?.photos?.length - 1}
                                onClick={() => {
                                    incrementImage(1);
                                    setIsZoomed(false);
                                }}
                                
                            >
                                <ChevronRightIcon className="h-6 w-6" />
                            </Button>
                        </Group>
                    </div>

                    <Text size="sm">{currentRow?.name}</Text>
                    <Text size="sm">Photo {imageToShow.index + 1} of {imageToShow.sectionPhotos.length}</Text>

                    <Group position="center" spacing="md">
                        <Button
                            color="blue"
                            onClick={handleDownload}
                            onTouchEnd={handleDownload}
                        >
                            Download
                        </Button>
                        <Button
                            color="red"
                            onClick={enhancedHandleDelete}
                            onTouchEnd={enhancedHandleDelete}
                        >
                            Delete
                        </Button>
                    </Group>
                </Stack>
            </div>
        </Modal>
    );
}

export default ViewImage;
